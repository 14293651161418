import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDropzone } from 'react-dropzone';
// @mui
import { Box, Stack, Button, IconButton, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
// assets
import { UploadIllustration } from '../../assets/illustrations';
//
import Iconify from '../iconify';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
import SingleFilePreview from './preview/SingleFilePreview';
// ----------------------------------------------------------------------
const StyledDropZone = styled('div')(({ theme }) => ({
    outline: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
    '&:hover': {
        opacity: 0.72,
    },
}));
// ----------------------------------------------------------------------
export default function Upload({ disabled, multiple = false, error, helperText, 
//
file, onDelete, 
//
files, thumbnail, onUpload, onRemove, onRemoveAll, description, sx, ...other }) {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        multiple,
        disabled,
        ...other,
    });
    const hasFile = !!file && !multiple;
    const hasFiles = files && multiple && files.length > 0;
    const isError = isDragReject || !!error;
    return (_jsxs(Box, { sx: { width: 1, position: 'relative', ...sx }, children: [_jsxs(StyledDropZone, { ...getRootProps(), sx: {
                    ...(isDragActive && {
                        opacity: 0.72,
                    }),
                    ...(isError && {
                        color: 'error.main',
                        bgcolor: 'error.lighter',
                        borderColor: 'error.light',
                    }),
                    ...(disabled && {
                        opacity: 0.48,
                        pointerEvents: 'none',
                    }),
                    ...(hasFile && {
                        padding: '12% 0',
                    }),
                }, children: [_jsx("input", { ...getInputProps() }), _jsx(Placeholder, { sx: {
                            ...(hasFile && {
                                opacity: 0,
                            }),
                        } }), _jsx(Typography, { gutterBottom: true, color: "gray", variant: "body2", sx: { marginTop: 3, mb: 0, textAlign: 'center' }, children: description }), hasFile && _jsx(SingleFilePreview, { file: file })] }), helperText && helperText, _jsx(RejectionFiles, { fileRejections: fileRejections }), hasFile && onDelete && (_jsx(IconButton, { size: "small", onClick: onDelete, sx: {
                    top: 16,
                    right: 16,
                    zIndex: 9,
                    position: 'absolute',
                    color: (theme) => alpha(theme.palette.common.white, 0.8),
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                    '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                    },
                }, children: _jsx(Iconify, { icon: "eva:close-fill", width: 18 }) })), hasFiles && (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { my: 3 }, children: _jsx(MultiFilePreview, { files: files, thumbnail: thumbnail, onRemove: onRemove }) }), _jsxs(Stack, { direction: "row", justifyContent: "flex-end", spacing: 1.5, children: [onRemoveAll && (_jsx(Button, { color: "inherit", variant: "outlined", size: "small", onClick: onRemoveAll, children: "Enlever tout" })), onUpload && (_jsx(Button, { size: "small", variant: "contained", onClick: onUpload, children: "T\u00E9l\u00E9charger des fichiers" }))] })] }))] }));
}
// ----------------------------------------------------------------------
function Placeholder({ sx, ...other }) {
    return (_jsxs(Stack, { spacing: 5, alignItems: "center", justifyContent: "center", direction: {
            xs: 'column',
            md: 'row',
        }, sx: {
            width: 1,
            textAlign: {
                xs: 'center',
                md: 'left',
            },
            ...sx,
        }, ...other, children: [_jsx(UploadIllustration, { sx: { width: 220 } }), _jsxs("div", { children: [_jsx(Typography, { gutterBottom: true, variant: "h5", children: "D\u00E9poser ou s\u00E9lectionner un fichier" }), _jsxs(Typography, { variant: "body2", sx: { color: 'text.secondary' }, children: ["D\u00E9posez les fichiers ici ou cliquez sur", _jsx(Typography, { variant: "body2", component: "span", sx: {
                                    mx: 0.5,
                                    color: 'primary.main',
                                    textDecoration: 'underline',
                                }, children: "parcourir" }), "votre machine"] })] })] }));
}
